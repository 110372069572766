<template>
  <main class="mainHome">
    <div class="container-fluid banner-header cs">
      <div class="container h-100 col-12 col-sm-10 col-md-8 col-lg-6">
        <div class="d-flex justify-content-center ">
          <div class="row title_destaque">
            <div class="col-12 col-lg-11" data-aos="fade-down">
              <h1 class="text-center">{{ $t("labels.cvBuilder1") }}</h1>
              <h1 class="text-center">{{ $t("labels.cvBuilder2") }}</h1>
            </div>
            <div
              class="d-grid gap-2 col-12 mx-auto mt-3"
              data-aos-delay="100"
              data-aos="fade-down"
            >
              <p class="text-center">{{ $t("labels.createCurriculumDesc") }}</p>
            </div>

            <div
              class="d-grid gap-2 col-10 col-md-8 col-lg-6 mx-auto mt-2"
              data-aos-delay="200"
              data-aos="fade-down"
            >
              <button
                v-if="user"
                class="btn bg-p btn-lg rounded-pill"
                @click="$router.push('/Curriculum')"
              >
              <img class="me-2" src="@/assets/img/pdf-white.svg" alt="send"> {{ $t("labels.createYourCvNow") }}
              </button>

              <button
                ref="btnLoginModal"
                v-else
                data-bs-toggle="modal"
                data-bs-target="#mdLogin"
                class="btn bg-p btn-lg rounded-pill"
                type="button"
              >
              <img class="me-2" src="@/assets/img/pdf-white.svg" alt="send">  {{ $t("labels.createYourCvNow") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-3 px-lg-5 py-2 part2 text-center list_templates">
      <div class="row g-3">
        <CardImage
          @view="view"
          @personalize="personalize"
          class="card-img-t col-6 col-md-4 col-xl-3"
          v-for="n in templateOrder"
          :key="n"
          :n="n"
        />

        <div class="col-12 d-flex justify-content-end mt-0">
          <span
            class="click"
            data-aos="fade-up"
            v-if="user"
            @click="
              $router.push({
                name: 'CurriculumPersonalize',
                params: { personalize: 1 },
              })
            "
            >{{ $t("homeText.see_more_template") }}</span
          >
          <span
            class="click"
            data-aos="fade-up"
            v-else
            data-bs-toggle="modal"
            data-bs-target="#mdLogin"
            >{{ $t("homeText.see_more_template") }}</span
          >
        </div>
      </div>
    </div>

    <div class="container mt-5 p-2 p-lg-5 part2 text-center">
      <h2 data-aos="fade-down">{{ $t("labels.about") }} The my CV</h2>
      <hr class="mb-3" />
      <p
        data-aos="fade-down"
        data-aos-delay="200"
        class="ms-lg-5 ps-lg-5 me-lg-5 pe-lg-5"
      >
        {{ $t("homeText.about") }}
      </p>
    </div>

    <div class="container mt-3 part2">
      <div class="row d-flex justify-content-center">
        <div
          data-aos="fade-up"
          data-aos-delay="100"
          class="col-6 col-lg-3 p-3 h-100 d-flex justify-content-center row"
        >
          <div class="rounded-circle d-flex align-items-center card-circule">
            <div class="card-bg-img rounded" id="template_ico"></div>
          </div>
          <h5 class="text-center d-none d-md-block">
            {{ $t("labels.Multi_template") }}
          </h5>
          <h6 class="text-center d-md-none">
            {{ $t("labels.Multi_template") }}
          </h6>
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="200"
          class="col-6 col-lg-3 p-3 h-100 d-flex justify-content-center row"
        >
          <div class="rounded-circle d-flex align-items-center card-circule">
            <div class="card-bg-img rounded" id="customize_ico"></div>
          </div>
          <h5 class="text-center d-none d-md-block">
            {{ $t("labels.customizable") }}
          </h5>
          <h6 class="text-center d-md-none">{{ $t("labels.customizable") }}</h6>
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="col-6 col-lg-3 p-3 h-100 d-flex justify-content-center row"
        >
          <div class="rounded-circle d-flex align-items-center card-circule">
            <div class="card-bg-img rounded" id="qrcode_ico"></div>
          </div>
          <h5 class="text-center d-none d-md-block">
            {{ $t("labels.qrcode_link") }}
          </h5>
          <h6 class="text-center d-md-none">{{ $t("labels.qrcode_link") }}</h6>
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="400"
          class="col-6 col-lg-3 p-3 h-100 d-flex justify-content-center row"
        >
          <div class="rounded-circle d-flex align-items-center card-circule">
            <div class="card-bg-img rounded" id="pdf_ico"></div>
          </div>
          <h5 class="text-center d-none d-md-block">
            {{ $t("labels.exportable_to_pdf") }}
          </h5>
          <h6 class="text-center d-md-none">
            {{ $t("labels.exportable_to_pdf") }}
          </h6>
        </div>
      </div>
    </div>

    <section class="mt-5 section-p">
      <div class="container">
        <div class="row">
          <div
            data-aos="fade-right"
            data-aos-delay="100"
            class="col-12 col-lg-6 p-2 p-lg-5"
          >
            <div class="rounded h100 d-flex justify-content-center">
              <img src="../assets/img/sharing.svg" class="card-img-p" />
            </div>
          </div>

          <div
            data-aos="fade-left"
            data-aos-delay="100"
            class="col-12 col-lg-6 p-3"
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="row text-center">
                <div class="col-12">
                  <h2>{{ $t("labels.share_your_cv") }}</h2>
                </div>
                <div class="col-12">
                  <p>
                    {{ $t("homeText.share") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container mt-3 mb-5 cp part2">
        <div class="row">
          <div
            data-aos="fade-up-right"
            data-aos-delay="200"
            class="col-12 col-lg-5 p-3"
          >
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="row text-center">
                <div class="col-12">
                  <h2>{{ $t("labels.keep_updated") }}</h2>
                </div>
                <div class="col-12">
                  <p>{{ $t("homeText.keep_updated") }}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up-left"
            data-aos-delay="200"
            class="col-12 col-lg-7 p-3 order-first order-lg-last"
          >
            <div class="rounded h100 d-flex justify-content-center p-2 p-lg-5">
              <img
                src="../assets/img/customer.svg"
                class="card-img-kep-Updated"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Vuex from "vuex";

import CardImage from "@/components/subComponrnts/CardImage";

import actions from "@/plugins/actions";
export default {
  name: "Home",
  data() {
    return {
      templateOrder: [16, 8, 18, 15],
    };
  },
  components: {
    CardImage,
  },

  computed: {
    ...Vuex.mapGetters(["user"]),
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (window.scrollY > 360)
        this.templateOrder = [
          16,
          8,
          18,
          15,
          12,
          11,
          10,
          9,
          1,
          14,
          17,
          24,
          2,
          13,
          19,
          6,
          20,
          23,
          7,
          4,
          5,
          21,
          22,
          3,
        ];
    },
    personalize(id) {
      if (this.user) {
        this.$router.push({
          name: "CurriculumPersonalize",
          params: { personalize: id },
        });
      } else {
        this.$refs.btnLoginModal.click();
        actions.dispatch("selectTemplate", id);
      }
    },
    view(id) {
      if (this.user) {
        this.$router.push({
          name: "CurriculumView",
          params: { id: id, personalize: false },
        });
      } else {
        this.$refs.btnLoginModal.click();
        actions.dispatch("selectTemplate", id);
      }
    },
  },
};
</script>

<style scoped>
.list_templates {
  margin-top: -250px;
}

.title_destaque {
  margin-top: 150px;
}
.card-img-t {
  overflow: hidden;
}

main {
  scroll-behavior: smooth;

  overflow: hidden;
}

.card-circule {
  background-color: var(--cp);
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
}
.card-circule:hover {
  opacity: 0.7;
}
hr {
  border: 2px solid var(--cp);
  margin: auto;
  width: 150px;
  border-radius: 5px;
  opacity: 0.8;
  margin-top: -5px;
}

.card-img-p {
  max-width: 100%;
  max-height: 350px;
}
.card-img-kep-Updated {
  max-width: 100%;
  max-height: 600px;
}

#qrcode_ico {
  -webkit-mask: url(../assets/img/qrcode.svg) no-repeat center;
  mask: url(../assets/img/qrcode.svg) no-repeat center;
  -webkit-mask-size: 100% 100%;
}

#pdf_ico {
  -webkit-mask: url(../assets/img/pdf.svg) no-repeat center;
  mask: url(../assets/img/pdf.svg) no-repeat center;
  -webkit-mask-size: 100% 100%;
}

#template_ico {
  -webkit-mask: url(../assets/img/layout.svg) no-repeat center;
  mask: url(../assets/img/layout.svg) no-repeat center;
  -webkit-mask-size: 100% 100%;
}

#customize_ico {
  -webkit-mask: url(../assets/img/customize.svg) no-repeat center;
  mask: url(../assets/img/customize.svg) no-repeat center;
  -webkit-mask-size: 100% 100%;
}

.banner-header {
  background: linear-gradient(
      rgba(17, 70, 166, 0.9),
      rgba(17, 70, 166, 0.8),
      rgba(17, 70, 166, 0.74)
    ),
    url("../assets/img/bg1.svg");
  height: 850px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.card-bg-img {
  background-color: var(--cs);
  height: 50px;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .banner-header {
    background-position: bottom;
    background-size: contain;
    height: 500px;
  }
  .title_destaque {
    margin-top: 60px;
  }

  .list_templates {
    margin-top: -120px;
  }
}
</style>
