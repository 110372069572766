<template>
  <div>
    <div v-if="isLoading" class="rounded placeholder-glow" style="width: 100%">
      <span
        class="placeholder col-12 placeholder img"
        style="width: 100%; padding-top: 141%; position: relative"
      ></span>
    </div>
    <div v-show="!isLoading" class="overlayoneHome rounded">
      <img
        v-show="!isLoading"
        @load="loaded"
        rel="preload"
        data-aos="fade-up"
        :src="require(`@/assets/img/templats/${n}.jpg`)"
        class="img-fluid rounded"
      />
      <div class="overlayinnHome">
        <div
          class="d-flex align-items-center"
          style="height: 100%; width: 100%"
        >
          <div class="d-flex justify-content-center" style="width: 100%">
            <div class="row">
              <div class="col-12 mb-1  d-flex
                                    justify-content-center">
                <button
                 @click="$emit('view',  n)"
                 type="button"
                  class="btn btn-primary"
                >
                <img class="me-2" src="@/assets/img/pdf-white.svg" alt="send">   {{ $t("buttons.view") }}
                </button>
               
              </div>
              <div class="col-12 mt-2  d-flex
                                    justify-content-center">
                <button
                @click="$emit('personalize',  n)"
                  type="button"
                  class="btn btn-dark"
                >
                <img class="me-2" src="@/assets/img/customize-white.svg" alt="send">  {{ $t("buttons.personalize") }}
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardImage",
  props: ["n"],

  methods: {
    loaded() {
      this.isLoading = false;
    },
    addSoftSkill() {
      this.$emit("addSoftSkill");
    },
    removeSoftSkill(index) {
      this.$emit("removeSoftSkill", index);
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },
};
</script>
<style scoped>
.overlayoneHome {
  overflow: hidden;
  display: inline-block;
  position: relative;
}
.overlayoneHome:hover {
  transform: scale(1.03);
  transition: transform 0.3s;
}
.overlayinnHome {
  background-color: rgba(6, 3, 14, 0.3);
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  padding: 10px;
  transition: opacity 0.3s;
}
.overlayinnHome button {
  display: none;
}
.overlayinnHome:hover button {
  display: inline-block;
}
.overlayinnHome:hover {
  opacity: 1;
  transition: opacity 0.3s;
}
.overlayoneHome:hover .page {
  transform: scale(1.01);
  transition: transform 0.3s;
}

@media only screen and (max-width: 770px) {
  .overlayinnHome:hover button {
    animation: disable-pointer-events 500ms;
  }
}

@keyframes disable-pointer-events {
  0%,
  99% {
    pointer-events: none;
  }
}
</style>
